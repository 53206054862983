import React from 'react'
import {Radio, ParagraphText} from '@thryvlabs/maverick'
import {
  CatalogSectionContainer,
  PlanOptionContainer,
} from '../ui/command-center-layout'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {formatPrice} from '../../utils/currency-format'

const checkIfAvailableForUpgrade = ({itemsInCart, allItems, name}) => {
  const currentIndex = allItems.findIndex(
    ({name: itemName}) => itemName === name,
  )
  const centerType = name.split(' ')[0]
  const isCenterTypeAlreadyIncluded = itemsInCart.find(({name}) =>
    name.includes(centerType),
  )
  const alreadyIncludedCenterIndex = allItems.findIndex(
    ({name}) => isCenterTypeAlreadyIncluded?.name === name,
  )

  const mcUnavailableForUpgrade =
    itemsInCart.some(({name}) => name === 'Thryv Leads') &&
    name.includes('Marketing')
  return mcUnavailableForUpgrade
    ? false
    : currentIndex >= alreadyIncludedCenterIndex
}

const checkIfPlanIsUnavailableForRole = ({
  planType,
  name,
  centersAndAppsInCart,
  hasBusinessCenterAccess,
  hasMarketingCenterAccess,
}) => {
  if (planType !== 'center') return false

  const plans = ['marketing', 'business']
  const nameLower = name.toLowerCase()
  const isBCorMC = plans.find(plan => nameLower.includes(plan))

  if (!isBCorMC) return false

  if (isBCorMC) {
    if (nameLower.includes('business')) {
      if (!hasBusinessCenterAccess) {
        const isAlreadyIncluded = centersAndAppsInCart.some(({name}) =>
          name.toLowerCase().includes(isBCorMC),
        )
        return !isAlreadyIncluded
      } else {
        return false
      }
    } else if (nameLower.includes('marketing')) {
      if (!hasMarketingCenterAccess) {
        const isAlreadyIncluded = centersAndAppsInCart.some(({name}) =>
          name.toLowerCase().includes(isBCorMC),
        )
        return !isAlreadyIncluded
      } else {
        return false
      }
    }
  }
}

const checkIfExcluded = ({
  itemsInCart,
  isItemAvailableForUpgrade,
  itemCode,
}) => {
  const isExcluded = itemsInCart.some(
    ({excludes, entitlements}) =>
      excludes?.includes(itemCode) ||
      entitlements?.some(({excludes}) => excludes?.includes(itemCode)),
  )

  return isExcluded && !isItemAvailableForUpgrade
}

function PlanOption({
  name,
  amount,
  img,
  entitlements,
  selectedPlan,
  setSelectedPlan,
  id,
  excludes,
  requiresCenter,
  planType,
  code,
  centersAndAppsInCart,
  setSelectedOptions,
  setQuantity,
  tier,
  isUpgrade,
  centers,
  isSEOMBGDisabled,
  setIsWebsiteSettingsModalVisible,
  hasBusinessCenterAccess,
  hasMarketingCenterAccess,
  subtitle,
}) {
  const {
    frontendMcBoostedListings,
    //frontendBcDisplayRestrictions,
    //frontendMcDisplayRestrictions,
  } = useFlags()
  const isCenter = planType === 'center' && name.includes('Center')
  const isItemAvailableForUpgrade =
    isCenter &&
    isUpgrade &&
    checkIfAvailableForUpgrade({
      itemsInCart: centersAndAppsInCart,
      allItems: centers,
      name,
    })
  const isFree = name.toLowerCase().includes('basic')
  const isExcluded = checkIfExcluded({
    itemsInCart: centersAndAppsInCart,
    isItemAvailableForUpgrade,
    itemCode: code,
  })
  const isAppAlreadyIncluded =
    planType === 'app' &&
    centersAndAppsInCart.some(
      ({code: appCode, alreadyIncluded}) => appCode === code && alreadyIncluded,
    )
  const isInCart = centersAndAppsInCart.some(
    ({code: itemCode, alreadyIncluded}) =>
      itemCode === code && !alreadyIncluded,
  )
  const isWebsiteAddedAsOption = centersAndAppsInCart.some(({entitlements}) =>
    entitlements?.some(({name}) => name === 'Professionally Designed Website'),
  )
  const isWebsiteAppUnavailable =
    name === 'Professionally Designed Website' && isWebsiteAddedAsOption
  const isSEOMBGUnavailable =
    isSEOMBGDisabled && name === 'SEO Money Back Guarantee'
  const isRoleUnableToSelectCenter = checkIfPlanIsUnavailableForRole({
    planType,
    name,
    centersAndAppsInCart,
    hasBusinessCenterAccess,
    hasMarketingCenterAccess,
  })
  let isMcaaddonInCart = false
  if (code === 'addon-5-mcstartgr' || code === 'addon-5-mcexpangr') {
    isMcaaddonInCart = centersAndAppsInCart.some(
      ({code: itemCode}) => itemCode === 'addon-5-mcexpangr',
    )
  }
  const isDisabled =
    isFree ||
    isExcluded ||
    isInCart ||
    isRoleUnableToSelectCenter ||
    isWebsiteAppUnavailable ||
    isSEOMBGUnavailable ||
    isAppAlreadyIncluded ||
    isMcaaddonInCart
  const isSEO = ['seombg-5-thryv-m2m', 'seo-5-thryv-m2m'].includes(code)

  const handleSelect = () => {
    if (name === selectedPlan?.name || isDisabled) return
    if (name === 'Professionally Designed Website')
      setIsWebsiteSettingsModalVisible(true)
    setQuantity(1)
    setSelectedOptions([])
    setSelectedPlan({
      name,
      amount,
      img,
      entitlements,
      id,
      planType,
      excludes,
      requiresCenter,
      code,
      ...(tier && {tier}),
    })
  }
  if (code === 'addon-5-mcstartgr' || code === 'addon-5-mcexpangr') {
    const isMCInCart = centersAndAppsInCart.some(
      ({code: itemCode}) =>
        itemCode === 'mc_plus-5-thryv-m2m' || itemCode === 'mc_pro-5-thryv-m2m',
    )
    if (!isMCInCart) {
      return false
    } else {
      if (!frontendMcBoostedListings) {
        return false
      }
    }
  }

  return (
    <PlanOptionContainer
      onClick={handleSelect}
      selected={name === selectedPlan?.name}
      isFree={isFree}
      isUnavailable={isDisabled}
      data-testid={`plan-option-${id}`}
    >
      <div className="d-flex align-items-center">
        <Radio
          labelClassName="m-0"
          toggled={name === selectedPlan?.name}
          disabled={isDisabled}
        />
        <img
          src={img}
          alt="plan logo"
          height={32}
          width={32}
          className="logo"
        />
        <div>
          <p
            className="m-0 dark-text"
            style={{
              color: isDisabled && '#A3A5A7',
            }}
          >
            {name}
          </p>
          {subtitle && (
            <p
              className="m-0 dark-text"
              style={{
                color: isDisabled ? '#A3A5A7' : '#808080',
                fontSize: '12px',
              }}
            >
              {subtitle}
            </p>
          )}
        </div>
      </div>
      <p className="m-0 dark-text">{isSEO ? 'Varies' : formatPrice(amount)}</p>
    </PlanOptionContainer>
  )
}

function CentersAndApps({
  data,
  selectedPlan,
  setSelectedPlan,
  selectedCentersAndApps,
  defaultCentersAndApps,
  setSelectedOptions,
  setQuantity,
  isUpgrade,
  isSEOMBGDisabled,
  setIsWebsiteSettingsModalVisible,
  selectedCountry,
  checkLegacySEO,
  accountSubscriptionData,
  hasReportingCenterAccess,
  hasBusinessCenterAccess,
  hasMarketingCenterAccess,
}) {
  // Remove new SEO base product, if seo-non guarantee or seo mbg is already present
  // const isLegacySEOPresent = selectedCentersAndApps.apps.find(app =>
  //   ['seombg-5-thryv-m2m', 'seo-5-thryv-m2m'].includes(app.code),
  // )

  // if (isLegacySEOPresent) {
  // if (
  //   data.centers.findIndex(ele => {
  //     return ele.code === 'seobase-5-thryv-m2m'
  //   }) >= 0
  // )
  //   data.centers.splice(
  //     data.centers.findIndex(ele => {
  //       return ele.code === 'seobase-5-thryv-m2m'
  //     }),
  //     1,
  //   )
  // }

  // console.log('isLegacySEOPresent :: ', isLegacySEOPresent)
  // console.log({data})
  // Remove the SEO keyword and SEO powerboost addons
  if (
    data.apps.findIndex(ele => {
      return ele.code === 'addon-5-seopb-m2m'
    }) >= 0
  )
    data.apps.splice(
      data.apps.findIndex(ele => {
        return ele.code === 'addon-5-seopb-m2m'
      }),
      1,
    )
  if (
    data.apps.findIndex(ele => {
      return ele.code === 'addon-5-seogeo-m2m'
    }) >= 0
  )
    data.apps.splice(
      data.apps.findIndex(ele => {
        return ele.code === 'addon-5-seogeo-m2m'
      }),
      1,
    )
  if (
    data.apps.findIndex(ele => {
      return ele.code === 'addon-5-seokey-m2m'
    }) >= 0
  )
    data.apps.splice(
      data.apps.findIndex(ele => {
        return ele.code === 'addon-5-seokey-m2m'
      }),
      1,
    )

  console.log('apps : ', data.apps)
  const defaultFreePlan = defaultCentersAndApps.centers.find(center =>
    center.name.includes('Basic'),
  )
  const centersAndAppsInCart = [
    ...selectedCentersAndApps.centers,
    ...selectedCentersAndApps.apps,
    ...selectedCentersAndApps.oneTimes,
  ]
  const isSelectedPlanSEO = ['seombg-5-thryv-m2m', 'seo-5-thryv-m2m'].includes(
    selectedPlan?.code,
  )

  const isRequiredCenterAndAddonInCart = (requiredCenters, requiredAddons) => {
    let isCenterPresent = false
    let isAddonPresent = false

    for (const item of centersAndAppsInCart) {
      if (requiredCenters.length > 0 && requiredCenters.includes(item.code)) {
        isCenterPresent = true
      }
      if (
        requiredAddons.includes(item.code) ||
        (item.entitlements &&
          item.entitlements.some(entitlement =>
            requiredAddons.includes(entitlement.code),
          ))
      ) {
        isAddonPresent = true
      }
      if (
        requiredAddons.length === 0 &&
        requiredCenters.length > 0 &&
        isCenterPresent
      ) {
        return true
      }
      if (requiredCenters.length > 0 && isCenterPresent && isAddonPresent) {
        return true
      }
      if (
        requiredAddons.length > 0 &&
        requiredCenters.length === 0 &&
        isAddonPresent
      ) {
        return true
      }
    }
    return false
  }
  const isNotEmpty = array => {
    if (array?.length > 0) {
      return true
    }
    return false
  }
  return (
    <CatalogSectionContainer style={{padding: '17px 0 0 0'}}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingRight: '10px',
        }}
      >
        <ParagraphText className="mb-3 font-weight-bold" variant="lg">
          Centers and Apps
        </ParagraphText>
        {selectedCountry === 'AU' && (
          <ParagraphText
            variant="sm"
            style={{color: '#4D4D4D', fontSize: '14px'}}
          >
            *All Plans include GST {isSelectedPlanSEO && 'except SEO'}
          </ParagraphText>
        )}
      </div>
      <div className="plans-container">
        {data?.centers
          .filter(center => {
            const isRequiredItemInCart =
              (isNotEmpty(center?.requiresAddon) ||
                isNotEmpty(center?.requiresCenter)) &&
              isRequiredCenterAndAddonInCart(
                center.requiresCenter,
                center.requiresAddon,
              )
            const hasMCProEnhanced = defaultCentersAndApps.centers.some(
              ({code}) => code === 'mc_pro_enh-5-thryv-m2m',
            )
            const hasMCPlan = defaultCentersAndApps.centers.some(({code}) =>
              ['mc_pro-5-thryv-m2m', 'mc_plus-5-thryv-m2m'].includes(code),
            )
            //===========================================//
            //  code added to hide MC Pro Enhanced  as it's as per requirement in case its need revert then remove or comment the code

            if (!hasMCProEnhanced && center.code === 'mc_pro_enh-5-thryv-m2m') {
              return false
            }
            //===========================================//
            const isMCPlan = [
              'mc_pro-5-thryv-m2m',
              'mc_plus-5-thryv-m2m',
            ].includes(center.codee)
            const isMCProEnhanced = center.code === 'mc_pro_enh-5-thryv-m2m'
            const isReportingCenter = center.code === 'rc_plus-5-thryv-m2m'

            let parentSubscriptionsTotal = 0,
              childSubscriptionsTotal = 0

            if (
              accountSubscriptionData?.parentSubscriptions &&
              accountSubscriptionData?.parentSubscriptions.length > 0
            ) {
              accountSubscriptionData?.parentSubscriptions.forEach(
                item =>
                  (parentSubscriptionsTotal =
                    parentSubscriptionsTotal + item.total),
              )
            }
            if (
              accountSubscriptionData?.childSubscriptions &&
              accountSubscriptionData?.childSubscriptions.length > 0
            ) {
              accountSubscriptionData?.childSubscriptions.forEach(
                item =>
                  (childSubscriptionsTotal =
                    childSubscriptionsTotal + item.total),
              )
            }
            if (center.code === 'seo-5-thryv-m2m') {
              if (checkLegacySEO) {
                if (parentSubscriptionsTotal + childSubscriptionsTotal > 0) {
                  return true
                } else {
                  return false
                }
              }
            }
            if (
              (isNotEmpty(center?.requiresAddon) ||
                isNotEmpty(center?.requiresCenter)) &&
              !isRequiredItemInCart
            ) {
              return false
            }
            if (center.name.includes('Basic')) {
              return center.name === defaultFreePlan?.name
            }
            if (center.code === '247support-m2m') return false
            if (hasMCPlan && isMCProEnhanced) return false
            if (hasMCProEnhanced && isMCPlan) return false
            if (!hasReportingCenterAccess && isReportingCenter) return false

            return true
          })
          .map(
            (
              {
                name,
                amount,
                img,
                entitlements,
                id,
                code,
                excludes,
                requiresCenter,
                tier,
              },
              _,
              centers,
            ) => (
              <PlanOption
                key={id}
                name={name}
                amount={amount}
                img={img}
                entitlements={entitlements}
                id={id}
                selectedPlan={selectedPlan}
                setSelectedPlan={setSelectedPlan}
                excludes={excludes}
                requiresCenter={requiresCenter}
                code={code}
                planType="center"
                centersAndAppsInCart={centersAndAppsInCart}
                setSelectedOptions={setSelectedOptions}
                setQuantity={setQuantity}
                tier={tier}
                isUpgrade={isUpgrade}
                centers={centers}
                isSEOMBGDisabled={isSEOMBGDisabled}
                hasBusinessCenterAccess={hasBusinessCenterAccess}
                hasMarketingCenterAccess={hasMarketingCenterAccess}
                subtitle={
                  code === 'mc_pro_enh-5-thryv-m2m' &&
                  'For legacy upgrades only'
                }
              />
            ),
          )}
        {data?.apps
          .filter(({requiresCenter = [], requiresAddon = []}) => {
            return (
              (requiresCenter.length === 0 && requiresAddon.length === 0) ||
              isRequiredCenterAndAddonInCart(requiresCenter, requiresAddon)
            )
          })
          .map(({name, amount, img, id, excludes, requiresCenter, code}) => (
            <PlanOption
              key={id}
              name={name}
              amount={amount}
              img={img}
              id={id}
              code={code}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              excludes={excludes}
              requiresCenter={requiresCenter}
              planType="app"
              centersAndAppsInCart={centersAndAppsInCart}
              setSelectedOptions={setSelectedOptions}
              setQuantity={setQuantity}
              setIsWebsiteSettingsModalVisible={
                setIsWebsiteSettingsModalVisible
              }
            />
          ))}
        {data?.oneTimes
          .filter(item => {
            if (!item.requires) {
              return false
            }
            const cartCodes = centersAndAppsInCart.flatMap(
              ({code, entitlements}) => [
                code,
                ...(entitlements || []).map(e => e.code),
              ],
            )
            const isRequiredItemInCart = cartCodes.some(code =>
              item.requires.includes(code),
            )
            return isRequiredItemInCart || item.requires.length === 0
          })
          .map(({name, amount, id, code, img}) => (
            <PlanOption
              key={id}
              id={id}
              name={name}
              amount={amount}
              code={code}
              img={img}
              centersAndAppsInCart={centersAndAppsInCart}
              planType="oneTime"
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              setSelectedOptions={setSelectedOptions}
              setQuantity={setQuantity}
            />
          ))}
      </div>
    </CatalogSectionContainer>
  )
}

export {CentersAndApps}
