import React, {useState, useEffect, useCallback, useRef} from 'react'
import {CentersAndApps} from '../components/catalog-components/centers-and-apps'
import {CommandCenterLayout} from '../components/command-center-layout'
import {Options} from '../components/catalog-components/options'
import {OrderSummary} from '../components/catalog-components/order-summary'
import {useAuthClient} from '../utils/use-auth-client'
import {useAsync} from '../utils/use-async'
import {useAuth0} from '@auth0/auth0-react'

import {
  commandCenterIcon,
  marketingCenterIcon,
  reportingCenterIcon,
  enhancedAccessibilityIcon,
  signaturesIcon,
  thryvLeadsIcon,
  businessCenterIcon,
  seoIcon,
  hipaaIcon,
  websiteBuilderIcon,
  cloverAppIcon,
  constantContactIcon,
  copperAppIcon,
  xeroAppIcon,
  googleSheetAppIcon,
  hubspotAppIcon,
  insightlyAppIcon,
  instagramAppIcon,
  jotFormIcon,
  mailchimpIcon,
  myobAppIcon,
  quickbooksAppIcon,
  quickbooksDesktopIcon,
  ringCentralIcon,
  shopifyAppIcon,
  smartDataImporterIcon,
  twillioAppIcon,
  webchatAppIcon,
  facebookMessengerIconApp,
  typeFormIcon,
  restrictedAccessIcon,
  gmailLogo,
  marketplaceIcon,
  logoBuilderIcon,
  customVideoShootIcon,
  listingsAppIcon,
  growthPackageIcon,
  seoKeywordsIcon,
  seoPowerboostIcon,
} from '../assets'
import {COUNTRIES, CC_CURRENCIES} from '../utils/constants'
import {useLocation} from 'react-router'
import {structuredClone} from 'core-js'
import {UpdateAddressModal} from '../components/update-address-modal'
import {BC_PLANS, MC_PLANS} from '../utils/constants'

const PLANS_ICONS = [
  {
    name: 'command center',
    icon: commandCenterIcon,
  },
  {
    name: 'business center',
    icon: businessCenterIcon,
  },
  {
    name: 'marketing center',
    icon: marketingCenterIcon,
  },
  {
    name: 'reporting center',
    icon: reportingCenterIcon,
  },
  {
    name: 'enhanced accessibility',
    icon: enhancedAccessibilityIcon,
  },
  {
    name: 'signatures',
    icon: signaturesIcon,
  },
  {
    name: 'leads',
    icon: thryvLeadsIcon,
  },
  {
    name: 'seo',
    icon: seoIcon,
  },
  {
    name: 'hipaa',
    icon: hipaaIcon,
  },
  {
    name: 'website',
    icon: websiteBuilderIcon,
  },
  {
    name: 'clover',
    icon: cloverAppIcon,
  },
  {
    name: 'constant contact',
    icon: constantContactIcon,
  },
  {
    name: 'copper',
    icon: copperAppIcon,
  },
  {
    name: 'xero app',
    icon: xeroAppIcon,
  },
  {
    name: 'google sheets',
    icon: googleSheetAppIcon,
  },
  {
    name: 'hubspot',
    icon: hubspotAppIcon,
  },
  {
    name: 'insightly',
    icon: insightlyAppIcon,
  },
  {
    name: 'instagram messenger',
    icon: instagramAppIcon,
  },
  {
    name: 'jotform',
    icon: jotFormIcon,
  },
  {
    name: 'mailchimp',
    icon: mailchimpIcon,
  },
  {
    name: 'myob',
    icon: myobAppIcon,
  },
  {
    name: 'quickbooks app',
    icon: quickbooksAppIcon,
  },
  {
    name: 'quickbooks desktop',
    icon: quickbooksDesktopIcon,
  },
  {
    name: 'ringcentral',
    icon: ringCentralIcon,
  },
  {
    name: 'shopify',
    icon: shopifyAppIcon,
  },
  {
    name: 'app-smartdataimporter',
    icon: smartDataImporterIcon,
  },
  {
    name: 'twilio app',
    icon: twillioAppIcon,
  },
  {
    name: 'web chat app',
    icon: webchatAppIcon,
  },
  {
    name: 'facebook business messenger app',
    icon: facebookMessengerIconApp,
  },
  {
    name: 'typeform app',
    icon: typeFormIcon,
  },
  {
    name: 'starter growth package',
    icon: growthPackageIcon,
  },
  {
    name: 'expanded growth package',
    icon: growthPackageIcon,
  },
  {
    name: 'restricted access',
    icon: restrictedAccessIcon,
  },
  {
    name: 'gmail',
    icon: gmailLogo,
  },
  {
    name: 'marketplace',
    icon: marketplaceIcon,
  },
  {
    name: 'logo',
    icon: logoBuilderIcon,
  },
  {
    name: 'custom video',
    icon: customVideoShootIcon,
  },
  {
    name: 'listings management',
    icon: listingsAppIcon,
  },
  {
    name: 'seo keywords',
    icon: seoKeywordsIcon,
  },
  {
    name: 'seo powerboost',
    icon: seoPowerboostIcon,
  },
]

const HIPAA_COMPLIANT_INDUSTRIES = [
  '52k10f2ml8m1f41u', // dentists
  'pcqjcmfqjvknm31t', // health & wellness
  'e8cgl2m7jp221sx7', // healthcare providers
]

const DEFAULT_MC_CONFIG = {
  isValid: false,
  value: '',
  shouldValidate: false,
  hideDomain: false,
}

const DEFAULT_SEO_CONFIG = {
  isValid: false,
  value: '',
}

const findUnitAmount = (currencies, selectedCountry, seatQty) => {
  const unitAmount = currencies.find(
    currency =>
      currency.currency === CC_CURRENCIES[COUNTRIES[selectedCountry].value],
  )?.unitAmount
  if (seatQty) {
    return unitAmount * seatQty
  }
  return unitAmount
}

const getPlanIcon = name => {
  const planItem = PLANS_ICONS.filter(item =>
    name.toLowerCase()?.includes(item.name),
  )
  return planItem[0]?.icon
}

const findTierCurrencies = (tiers, seatQty) => {
  let tier
  tiers?.forEach(t => {
    if (
      t.endingQuantity >= seatQty &&
      (!tier || t.endingQuantity < tier.endingQuantity)
    ) {
      tier = t
    }
  })
  return tier?.currencies
}

const findTier = (tierCurrencies, selectedCountry) => {
  return tierCurrencies?.find(
    currency =>
      currency.currency === CC_CURRENCIES[COUNTRIES[selectedCountry].value],
  )
}

const formatItem = (item, selectedCountry, itemType, tierSettings) => {
  const tierCurrencies = findTierCurrencies(
    tierSettings?.tiers,
    tierSettings?.seatQty,
  )
  const amount = findUnitAmount(
    tierCurrencies ? tierCurrencies : item.currencies,
    selectedCountry,
    tierCurrencies ? tierSettings?.seatQty : null,
  )

  const tier = findTier(tierCurrencies, selectedCountry)

  const baseData = {
    code: item.code,
    name: item.name,
    id: item.id,
    isRequired: item.isRequired,
    amount,
    ...(item?.requiresAddon && {requiresAddon: item.requiresAddon}),
    ...(isNaN(amount) && {isNotAvailable: true}),
  }
  if (itemType === 'otFee') {
    return {
      ...baseData,
      appliesTo: item.appliesTo,
      requires: item.requires,
      img: getPlanIcon(item.name),
    }
  }
  if (itemType === 'ent') {
    return {
      ...baseData,
      ...(item?.excludes && {excludes: item.excludes}),
    }
  }
  return {
    ...baseData,
    entitlements: item.entitlements.filter(
      entitlement => !entitlement.isNotAvailable,
    ),
    requiresCenter: item.requiresCenter,
    excludes: item.excludes,
    img: getPlanIcon(item.name),
    ...(item?.addons && {tier}),
  }
}

function Catalog({
  nextStep,
  prevStep,
  selectedCountry,
  CCAccountInfo,
  industryTemplate,
  prevCentersAndApps,
  prevCode,
  ccCouponInfo,
  setCcCouponInfo,
  setAccountInfo,
  setAccountSubscriptionData,
  accountSubscriptionData,
  accountInfo,
  setCurrentStep,
  order,
  setOrder,
  defaultUpgradeCentersAndApps,
  defaultExpiredCentersAndApps,
  setOrderCouponCodes,
  setIncentiveCoupon,
  all5PlanPrices,
  isEaidFound,
  setIsEaidFound,
  cohortEaid,
  setCohortEaid,
  upgradePaymentPreview,
  setUpgradePaymentPreview,
  setPreviewRequestFinished,
  ccUpgradeCoupons,
  setCcUpgradeCoupons,
  resetAutoCoupons,
  setIsInvoiceBilled,
  setAllowInvoice,
  updateAddressInfo,
  allowInvoice,
  isCohort,
  setIsCohort,
  isNewAccountOrderCreated,
  orderData5CU,
  setOrderData5CU,
  captureOrder5NewUpgrade,
  setOrder5LinesDiscounts,
  isWebsiteValid,
  setIsWebsiteValid,
}) {
  const {user} = useAuth0()
  const [plans, setPlans] = useState(null)
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [selectedCentersAndApps, setSelectedCentersAndApps] = useState({
    centers: [],
    apps: [],
    oneTimes: [],
  })
  const [code, setCode] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [selectedOptions, setSelectedOptions] = useState([])
  const [proposalId, setProposalId] = useState('')
  const [proposalValidation, setProposalValidation] = useState(null)
  const [hasBusinessCenterAccess, setHasBusinessCenterAccess] = useState(true)
  const [hasMarketingCenterAccess, setHasMarketingCenterAccess] = useState(true)
  const [hasReportingCenterAccess, setHasReportingCenterAccess] =
    useState(false)

  const [alphaSEOFromRecurly, setAlphaSEOFromRecurly] = useState({
    alphaProposalId: '',
    alphaDomain: '',
    alphaAmount: 0,
    // alphaCategories: '',
    // alphaGeos: '',
    alphaKeyCount: 0,
    alphaPowerboostCount: 0,
  })
  const [alphaProposalId, setAlphaProposalId] = useState('')
  const [alphaDomain, setAlphaDomain] = useState('')
  const [alphaAmount, setAlphaAmount] = useState(null)
  // const [categories, setCategories] = useState('')
  // const [geos, setGeos] = useState('')
  const [isExternalDomain, setIsExternalDomain] = useState(null)
  const [alphaKeyCount, setAlphaKeyCount] = useState(null)
  const [alphaPowerboostCount, setAlphaPowerboostCount] = useState(null)

  const [thryvLeadsId, setThryvLeadsId] = useState('')
  const [thryvLeadsValidation, setThryvLeadsValidation] = useState(null)
  const [mcDomainConfig, setMcDomainConfig] = useState(DEFAULT_MC_CONFIG)
  const [seoDomainConfig, setSeoDomainConfig] = useState(DEFAULT_SEO_CONFIG)
  const [futureBillingDate, setFutureBillingDate] = useState()
  const [sensisSiteDomain, setSensisSiteDomain] = useState('')
  const [quantity, setQuantity] = useState(1)
  const [totalPrice, setTotalPrice] = useState('$0.00')
  const [isSEOMBGDisabled, setIsSEOMBGDisabled] = useState(false)
  const [accountSubscriptions, setAccountSubscriptions] = useState(null)
  const [isWebsiteSettingsModalVisible, setIsWebsiteSettingsModalVisible] =
    useState(false)
  const [showUpdateAddressModal, setShowUpdateAddressModal] = useState(false)
  const [monthlyTotal, setMonthlyTotal] = useState(0)
  const defaultCentersAndApps = useRef({})
  const expiredCentersAndApps = useRef({})
  const [seatQty, setSeatQty] = useState(0)
  const [isLegacyUpgradeAllowed, setIsLegacyUpgradeAllowed] = useState(false)
  const client = useAuthClient()
  const {pathname} = useLocation()
  const {run} = useAsync({
    status: 'pending',
  })

  const isUpgrade = pathname === '/order/upgrade'
  const isNewOrder = pathname === '/order/new'
  const showHipaa = HIPAA_COMPLIANT_INDUSTRIES.includes(industryTemplate)
  const getAccountCentersAndApps = allSubscriptions => {
    const STARTER_PACKAGE_ADDON_CODES = [
      'addon-5-mcstartgr',
      'addon-5-mcexpangr',
    ]
    let centersAndApps = {centers: [], apps: []}

    allSubscriptions.forEach(subscription => {
      let found = false
      for (const center of plans.centers) {
        if (subscription.code === center.code) {
          let isGrowthPackageInCart = subscription.addons?.some(({code}) =>
            STARTER_PACKAGE_ADDON_CODES.includes(code),
          )
          const entitlements =
            subscription.code === 'seobase-5-thryv-m2m'
              ? []
              : subscription.addons.map(addon => {
                  const excludes = center.entitlements.find(
                    ({code}) => code === addon.code,
                  )?.excludes
                  return {
                    ...addon,
                    amount:
                      addon.unitAmount && addon.quantity
                        ? addon.unitAmount * addon.quantity
                        : 0,
                    alreadyIncluded: true,
                    // recurly_subscription_id: addon.subscriptionId,
                    excludes,
                  }
                })
          const formattedCenter = {
            ...center,
            entitlements,
            uuid: subscription.uuid,
            unitAmount: subscription.unitAmount,
            quantity: subscription.quantity,
            createdAt: subscription.createdAt,
            ...(isUpgrade && {alreadyIncluded: true}),
          }
          const isSEOorLeads =
            (subscription.code.includes('seo') &&
              subscription.code !== 'seobase-5-thryv-m2m') ||
            subscription.name === 'Thryv Leads'
          if (isSEOorLeads) {
            formattedCenter.amount = subscription.quantity
          }
          if (subscription.code === 'seobase-5-thryv-m2m') {
            formattedCenter.amount = subscription.quantity
            formattedCenter.quantity = 1
          }
          if (isGrowthPackageInCart) {
            const growthPackageAddOn = subscription.addons?.find(
              ({code}) =>
                code === 'addon-5-mcstartgr' || code === 'addon-5-mcexpangr',
            )
            growthPackageAddOn.img = getPlanIcon(growthPackageAddOn.name)
            growthPackageAddOn.amount = growthPackageAddOn.unitAmount
            // growthPackageAddOn.recurly_subscription_id =
            //   growthPackageAddOn.subscriptionId
            centersAndApps.apps.push(growthPackageAddOn)
          }
          centersAndApps.centers.push(formattedCenter)
          found = true
          break
        }
      }
      if (!found) {
        for (const app of plans.apps) {
          if (subscription.code === app.code) {
            centersAndApps.apps.push({
              ...app,
              uuid: subscription.uuid,
              unitAmount: subscription.unitAmount,
              quantity: subscription.quantity,
              ...(isUpgrade && {alreadyIncluded: true}),
            })
            found = true
            break
          }
        }
      }
      if (!found) {
        subscription.img = getPlanIcon(subscription.name)
        if (
          subscription.code === 'addon-5-seokey-m2m' ||
          subscription.code === 'addon-5-seopb-m2m'
        ) {
          if (subscription.code === 'addon-5-seokey-m2m')
            subscription.img = seoKeywordsIcon
          if (subscription.code === 'addon-5-seopb-m2m')
            subscription.img = seoPowerboostIcon
          subscription.amount = subscription.unitAmount
        } else {
          subscription.amount = subscription.unitAmount
            ? subscription.subtotal
            : 0
        }

        centersAndApps.apps.push(subscription)
      }
    })

    defaultCentersAndApps.current = {...centersAndApps, oneTimes: []}
    if (isUpgrade) defaultUpgradeCentersAndApps.current = centersAndApps
    console.log('centersAndApps', centersAndApps)
    return centersAndApps
  }

  const getExpiredAccountCentersAndApps = expiredSubscriptions => {
    const STARTER_PACKAGE_ADDON_CODES = [
      'addon-5-mcstartgr',
      'addon-5-mcexpangr',
    ]
    let centersAndApps = {centers: [], apps: []}

    expiredSubscriptions.forEach(subscription => {
      let found = false
      for (const center of plans.centers) {
        if (subscription.code === center.code) {
          let isGrowthPackageInCart = subscription.addons?.some(({code}) =>
            STARTER_PACKAGE_ADDON_CODES.includes(code),
          )
          const entitlements =
            subscription.code === 'seobase-5-thryv-m2m'
              ? []
              : subscription.addons.map(addon => {
                  const excludes = center.entitlements.find(
                    ({code}) => code === addon.code,
                  )?.excludes
                  return {
                    ...addon,
                    amount:
                      addon.unitAmount && addon.quantity
                        ? addon.unitAmount * addon.quantity
                        : 0,
                    alreadyIncluded: true,
                    excludes,
                  }
                })
          const formattedCenter = {
            ...center,
            entitlements,
            quantity: subscription.quantity,
            createdAt: subscription.createdAt,
            ...(isUpgrade && {alreadyIncluded: true}),
          }
          const isSEOorLeads =
            (subscription.code.includes('seo') &&
              subscription.code !== 'seobase-5-thryv-m2m') ||
            subscription.name === 'Thryv Leads'
          if (isSEOorLeads) {
            formattedCenter.amount = subscription.quantity
          }
          if (subscription.code === 'seobase-5-thryv-m2m') {
            formattedCenter.amount = subscription.quantity
            formattedCenter.quantity = 1
          }
          if (isGrowthPackageInCart) {
            const growthPackageAddOn = subscription.addons?.find(
              ({code}) =>
                code === 'addon-5-mcstartgr' || code === 'addon-5-mcexpangr',
            )
            growthPackageAddOn.img = getPlanIcon(growthPackageAddOn.name)
            growthPackageAddOn.amount = growthPackageAddOn.unitAmount
            centersAndApps.apps.push(growthPackageAddOn)
          }
          centersAndApps.centers.push(formattedCenter)
          found = true
          break
        }
      }
      if (!found) {
        for (const app of plans.apps) {
          if (subscription.code === app.code) {
            centersAndApps.apps.push({
              ...app,
              quantity: subscription.quantity,
              ...(isUpgrade && {alreadyIncluded: true}),
            })
            found = true
            break
          }
        }
      }
      if (!found) {
        subscription.img = getPlanIcon(subscription.name)
        if (
          subscription.code === 'addon-5-seokey-m2m' ||
          subscription.code === 'addon-5-seopb-m2m'
        ) {
          if (subscription.code === 'addon-5-seokey-m2m')
            subscription.img = seoKeywordsIcon
          if (subscription.code === 'addon-5-seopb-m2m')
            subscription.img = seoPowerboostIcon
          subscription.amount = subscription.unitAmount
        } else {
          subscription.amount = subscription.unitAmount
            ? subscription.subtotal
            : 0
        }

        centersAndApps.apps.push(subscription)
      }
    })

    expiredCentersAndApps.current = {...centersAndApps, oneTimes: []}
    if (isUpgrade) defaultExpiredCentersAndApps.current = centersAndApps
    return expiredCentersAndApps.current
  }

  const formatSelectedCentersAndApps = useCallback(() => {
    if (!plans || !accountSubscriptions) return
    const {
      childSubscriptions,
      parentSubscriptions,
      ccSeats,
      expiredParentSubscriptions,
      expiredChildSubscriptions,
    } = accountSubscriptions

    setSeatQty(ccSeats > 0 ? ccSeats : 1)
    const appMarketplace = childSubscriptions.find(
      ({code}) => code === 'appmarket-5-thryv-m2m',
    )
    const alphSEOProd = childSubscriptions.find(
      ({code}) => code === 'seobase-5-thryv-m2m',
    )

    const allSubscriptions = alphSEOProd
      ? [
          ...parentSubscriptions,
          ...childSubscriptions,
          ...appMarketplace.addons,
          ...alphSEOProd.addons,
        ]
      : [
          ...parentSubscriptions,
          ...childSubscriptions,
          ...appMarketplace.addons,
        ]

    const expiredSubscriptions = [
      ...expiredParentSubscriptions,
      ...expiredChildSubscriptions,
    ]
    const formattedCentersAndApps = getAccountCentersAndApps(allSubscriptions)
    setSelectedCentersAndApps(
      prevCentersAndApps
        ? prevCentersAndApps
        : {...formattedCentersAndApps, oneTimes: []},
    )
    getExpiredAccountCentersAndApps(expiredSubscriptions)
    if (prevCode) setCode(prevCode)
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevCentersAndApps, prevCode, plans, accountSubscriptions])

  const getPlansPrices = useCallback(() => {
    if (
      !all5PlanPrices ||
      (isUpgrade && !accountInfo.billingAddress?.country)
    ) {
      return
    }
    const formattedCentersAndApps = {
      centers: all5PlanPrices.centers
        .map(center => {
          const tiers = center?.addons?.[0].tiers
          return formatItem(
            center,
            selectedCountry,
            'center',
            center?.addons && {tiers, seatQty: seatQty},
          )
        })
        .filter(center => !center.isNotAvailable)
        .sort((a, b) => {
          function rank(plan) {
            const planName = plan.name.toLowerCase()
            if (planName.includes('command')) return 1
            if (planName.includes('business')) return 2
            if (planName.includes('marketing')) return 3
            if (planName.includes('reporting')) return 4
            return 5
          }
          const rankA = rank(a)
          const rankB = rank(b)
          if (rankA !== rankB) {
            return rankA - rankB
          } else {
            return a.amount - b.amount
          }
        }),
      apps: all5PlanPrices.apps
        .map(app => formatItem(app, selectedCountry, 'app'))
        .filter(
          app =>
            !app.isNotAvailable &&
            !(app.name === 'HIPAA' && !showHipaa) &&
            !(
              app.name === 'Restricted Access' &&
              showHipaa &&
              !['BB', 'KY'].includes(selectedCountry)
            ),
        )
        .sort((a, b) => {
          function rank(app) {
            const appName = app.name.toLowerCase()
            if (
              appName.includes('expanded growth package') ||
              appName.includes('starter growth package')
            )
              return 1
            return 2
          }
          const rankA = rank(a)
          const rankB = rank(b)
          if (rankA !== rankB) {
            return rankA - rankB
          } else {
            return a.amount - b.amount
          }
        }),
      oneTimes: all5PlanPrices.oneTimeFees
        .map(fee => formatItem(fee, selectedCountry, 'otFee'))
        .filter(otFee => !otFee.isNotAvailable),
    }

    const includedEntitlements =
      defaultCentersAndApps.current?.centers?.flatMap(
        ({entitlements}) => entitlements,
      )
    const includedApps = defaultCentersAndApps.current?.apps?.map(
      ({code}) => code,
    )

    formattedCentersAndApps.centers.forEach(center => {
      center.entitlements = center.entitlements.map((entitlement, index) => {
        const isEntitlementIncluded = includedEntitlements?.some(
          ({code}) => code === entitlement.code,
        )
        const oneTimeFees = formattedCentersAndApps?.oneTimes.filter(
          oneTime => oneTime.appliesTo.length > 0,
        )
        const applicableOneTimes = oneTimeFees.filter(fee =>
          fee.appliesTo.some(
            code => code === entitlement.code && !isEntitlementIncluded,
          ),
        )
        return {...entitlement, applicableOneTimes}
      })
    })

    formattedCentersAndApps.apps.forEach(app => {
      const isAppIncluded = includedApps?.some(code => code === app.code)
      const oneTimeFees = formattedCentersAndApps?.oneTimes.filter(
        oneTime => oneTime.appliesTo.length > 0,
      )
      const applicableOneTimes = oneTimeFees.filter(fee =>
        fee.appliesTo.some(code => code === app.code && !isAppIncluded),
      )
      const uniqueOneTimes = new Set(
        formattedCentersAndApps.oneTimes.concat(applicableOneTimes),
      )
      formattedCentersAndApps.oneTimes = Array.from(uniqueOneTimes)
    })
    const isListingManagementExist = selectedCentersAndApps.apps.find(
      ({code}) => code === 'app-listings',
    )
    if (!isListingManagementExist) {
      formattedCentersAndApps.centers.map(center => {
        if (BC_PLANS.includes(center.code)) {
          center.entitlements = center.entitlements.filter(
            item => item.code !== 'app-listings-mgmt-free',
          )
        }
        return center
      })
    }
    // if (!hasReportingCenterAccess) {
    //   const reportIndex = formattedCentersAndApps.centers.findIndex(
    //     item => item.code === 'rc_plus-5-thryv-m2m',
    //   )
    //   formattedCentersAndApps.centers.splice(reportIndex, 1)
    // }
    setPlans(formattedCentersAndApps)
    // eslint-disable-next-line
  }, [
    all5PlanPrices,
    isUpgrade,
    accountInfo?.billingAddress?.country,
    selectedCountry,
    seatQty,
    showHipaa,
  ])

  const handleStartOver = () => {
    console.log('Inside handle start over')
    setSelectedCentersAndApps(defaultCentersAndApps.current)
    setSelectedPlan(null)
    setSelectedOptions([])
    setProposalId('')
    setProposalValidation(null)
    if (alphaSEOFromRecurly.alphaProposalId > 0) {
      setAlphaProposalId(alphaSEOFromRecurly.alphaProposalId)
      setAlphaDomain(alphaSEOFromRecurly.alphaDomain)
      setAlphaAmount(alphaSEOFromRecurly.alphaAmount)
      // setCategories(alphaSEOFromRecurly.alphaCategories)
      // setGeos(alphaSEOFromRecurly.alphaGeos)
      setAlphaKeyCount(alphaSEOFromRecurly.alphaKeyCount)
      setAlphaPowerboostCount(alphaSEOFromRecurly.alphaPowerboostCount)
      setIsExternalDomain(null)
    } else {
      setAlphaProposalId('')
      setAlphaDomain('')
      setIsExternalDomain(null)
      setAlphaAmount(null)
      setAlphaKeyCount(null)
      setAlphaPowerboostCount(null)
    }

    setMcDomainConfig(DEFAULT_MC_CONFIG)
    setSeoDomainConfig(DEFAULT_SEO_CONFIG)
    setCode('')
    setCcCouponInfo(null)
    setThryvLeadsId('')
    setThryvLeadsValidation(null)
    setFutureBillingDate(undefined)
    setSensisSiteDomain('')
    setIncentiveCoupon(false)
    setIsSEOMBGDisabled(false)
    setOrderCouponCodes([])
    resetAutoCoupons()
    setIsLegacyUpgradeAllowed(false)
    setCohortEaid('')
    setIsEaidFound(false)
    setAllowInvoice(false)
    setIsCohort(false)
    if (setPreviewRequestFinished) setPreviewRequestFinished(false)
    if (upgradePaymentPreview) setUpgradePaymentPreview(null)
    if (ccUpgradeCoupons) setCcUpgradeCoupons(null)
    setOrder5LinesDiscounts([])
  }

  const calcTotal = items => {
    return items.reduce((acc, item) => {
      const price = item?.amount || 0
      const quantity = item.quantity || 1
      if (
        (item.name.includes('SEO') &&
          item.name !== 'SEO' &&
          item.name !== 'SEO Keywords' &&
          item.name !== 'SEO PowerBoost') ||
        item.name === 'Thryv Leads'
      ) {
        return acc + price
      }
      return acc + price * quantity
    }, 0)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await run(
          client('plans/features/Reporting_Center_Access', {method: 'GET'}),
        )
        for (let accessRow of response) {
          if (accessRow?.entity_type === 'Role') {
            if (user.user_roles.includes(accessRow?.entity_value)) {
              setHasReportingCenterAccess(true)
            }
          }
        }
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
  }, [client, run, user.user_roles])

  useEffect(() => {
    const featuresList = [
      'MC_Catalog_Restrictions',
      'BC_Catalog_Restrictions',
      'BC_Catalog_Provisions_Pilot',
      'MC_Catalog_Provisions_Pilot',
    ]
    const fetchData = async () => {
      try {
        const response = await run(
          client(`plans/features/${featuresList}`, {method: 'GET'}),
        )
        for (let accessRow of response) {
          if (
            accessRow?.entity_type === 'Role' &&
            accessRow?.feature_desc === 'BC_Catalog_Restrictions'
          ) {
            if (user.user_roles.includes(accessRow?.entity_value)) {
              setHasBusinessCenterAccess(false)
            }
          }
          if (
            accessRow?.entity_type === 'Role' &&
            accessRow?.feature_desc === 'MC_Catalog_Restrictions'
          ) {
            if (user.user_roles.includes(accessRow?.entity_value)) {
              setHasMarketingCenterAccess(false)
            }
          }
          if (
            accessRow?.entity_type === 'User' &&
            accessRow?.feature_desc === 'BC_Catalog_Provisions_Pilot'
          ) {
            if (user.network_id.includes(accessRow?.entity_value)) {
              setHasBusinessCenterAccess(true)
            }
          }
          if (
            accessRow?.entity_type === 'User' &&
            accessRow?.feature_desc === 'MC_Catalog_Provisions_Pilot'
          ) {
            if (user.network_id.includes(accessRow?.entity_value)) {
              setHasMarketingCenterAccess(true)
            }
          }
        }
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
  }, [client, run, user])

  useEffect(() => {
    const calculateTotalPrice = () => {
      const centersTotalNo24x7SupportFee = calcTotal(
        selectedCentersAndApps.centers.filter(
          ({code}) => code !== '247support-m2m',
        ),
      )
      const isBCorMCInCart = selectedCentersAndApps?.centers.some(
        ({code}) => BC_PLANS.includes(code) || MC_PLANS.includes(code),
      )
      const filteredApps = selectedCentersAndApps.apps.filter(({code}) => {
        const isListingsApp = code === 'app-listings'
        if (['seombg-5-thryv-m2m', 'seo-5-thryv-m2m'].includes(code)) {
          let isNewSEOExist = selectedCentersAndApps.centers.find(
            ({code}) => code === 'seobase-5-thryv-m2m',
          )
          if (isNewSEOExist) {
            return false
          }
          return true
        }
        if (isListingsApp) return !isBCorMCInCart
        return true
      })
      const centersTotal = calcTotal(selectedCentersAndApps.centers)
      const appsTotal = calcTotal(filteredApps)
      const oneTimesTotal = calcTotal(selectedCentersAndApps.oneTimes)
      const calculatedTotal =
        centersTotalNo24x7SupportFee + appsTotal + oneTimesTotal
      const monthlyTotal = centersTotal + appsTotal

      setMonthlyTotal(monthlyTotal)
      setTotalPrice(calculatedTotal)
    }
    calculateTotalPrice()
  }, [selectedCentersAndApps])

  useEffect(() => {
    if (
      accountSubscriptions &&
      !accountSubscriptions?.accountInfo?.allowIncentive
    ) {
      setIsCohort(true)
    }
  }, [accountSubscriptions, setIsCohort])

  useEffect(() => {
    if (!isUpgrade) return
    const planType = selectedPlan?.name.split(' ')[0]
    const isPlanTypeIncluded = selectedCentersAndApps.centers.some(({name}) =>
      name.includes(planType),
    )
    if (isPlanTypeIncluded) {
      const defaultOptions = selectedCentersAndApps.centers.find(({name}) =>
        name.includes(planType),
      ).entitlements
      setSelectedOptions(defaultOptions)
    } else {
      setSelectedOptions([])
    }
  }, [isUpgrade, selectedCentersAndApps.centers, selectedPlan?.name])

  useEffect(
    () => {
      const fetchAccountSubscriptions = async (retriesAmount = 3) => {
        try {
          const url = `accounts/5/subscriptions?thryvId=${CCAccountInfo.thryvId}`
          const response = await client(url)
          if (
            !response.childSubscriptions.find(
              sub => sub.code === 'appmarket-5-thryv-m2m',
            ) ||
            !response.parentSubscriptions.find(sub => sub.code.includes('cc_'))
          ) {
            throw Error('missing subscription')
          }

          setAccountSubscriptions(response)
          setAccountSubscriptionData(response)
          const {
            accountInfo: {
              businessAddress,
              firstName,
              lastName,
              company,
              industryId,
              email,
              abn,
              allowInvoice,
              eaid,
              hasPaidDesignFee,
              hasPaidOnboardingFee,
            },
            //accountInfo,
            childSubscriptions,
            parentSubscriptions,
            balanceInfo,
            recurlyAccount,
          } = response
          console.log(
            'catalog fetchAccountSubscriptions - response-----------',
            response,
          )
          console.log({hasPaidOnboardingFee, hasPaidDesignFee})
          const alphaSEO = response.childSubscriptions.find(
            sub => sub.code === 'seobase-5-thryv-m2m',
          )
          if (alphaSEO) {
            setAlphaSEOFromRecurly({
              alphaProposalId: alphaSEO.alphaProposalId,
              alphaDomain: alphaSEO.alphaDomain,
              alphaAmount: alphaSEO.quantity,
              // alphaCategories: alphaSEO.categories,
              // alphaGeos: alphaSEO.geos,
              alphaKeyCount:
                alphaSEO.addons.filter(
                  addon => addon.code === 'addon-5-seokey-m2m',
                ).length > 0
                  ? alphaSEO.addons.filter(
                      addon => addon.code === 'addon-5-seokey-m2m',
                    )[0].quantity
                  : 0,
              alphaPowerboostCount:
                alphaSEO.addons.filter(
                  addon => addon.code === 'addon-5-seopb-m2m',
                ).length > 0
                  ? alphaSEO.addons.filter(
                      addon => addon.code === 'addon-5-seopb-m2m',
                    )[0].quantity
                  : 0,
            })
            setAlphaAmount(alphaSEO.quantity)
            setAlphaDomain(alphaSEO.alphaDomain)
            setAlphaProposalId(alphaSEO.alphaProposalId)
            // setCategories(alphaSEO.categories)
            // setGeos(alphaSEO.geos)
            setAlphaKeyCount(
              alphaSEO.addons.filter(
                addon => addon.code === 'addon-5-seokey-m2m',
              ).length > 0
                ? alphaSEO.addons.filter(
                    addon => addon.code === 'addon-5-seokey-m2m',
                  )[0].quantity
                : 0,
            )
            setAlphaPowerboostCount(
              alphaSEO.addons.filter(
                addon => addon.code === 'addon-5-seopb-m2m',
              ).length > 0
                ? alphaSEO.addons.filter(
                    addon => addon.code === 'addon-5-seopb-m2m',
                  )[0].quantity
                : 0,
            )
          }
          if (allowInvoice) {
            setAllowInvoice(allowInvoice)
            setCohortEaid(eaid)
          }
          if (isNewOrder) {
            let orderData5 = {
              order,
              subscriptions: {
                childSubscriptions,
                parentSubscriptions,
              },
              accountInfo: {
                ...order.accountInformation,
                ...response.accountInfo,
                balanceInfo,
                recurlyEmail: email,
                recurlyFirst: firstName,
                recurlyLast: lastName,
                recurlyCompany: company,
                abn: abn?.replaceAll(' ', ''),
                billingAddress: {
                  ...businessAddress,
                  state: businessAddress.region,
                  zipCode: businessAddress.postalCode,
                },
              },
              recurlyAccount: recurlyAccount,
            }
            setOrderData5CU(orderData5)
            // createOrder(response)
            if (!isNewAccountOrderCreated) {
              captureOrder5NewUpgrade(
                'catalog-init-newAccOrder',
                'New',
                orderData5,
              )
            }
          }
          if (isUpgrade) {
            const tempOrderData5CU = {
              order,
              subscriptions: {
                childSubscriptions,
                parentSubscriptions,
              },
              accountInfo: {
                ...response.accountInfo,
                balanceInfo,
                recurlyEmail: email,
                recurlyFirst: firstName,
                recurlyLast: lastName,
                recurlyCompany: company,
                abn: abn?.replaceAll(' ', ''),
                billingAddress: {
                  ...businessAddress,
                  state: businessAddress.region,
                  zipCode: businessAddress.postalCode,
                },
              },
              recurlyAccount: recurlyAccount,
            }
            console.log('catalog isUpgrade - ordetData5cu', tempOrderData5CU)
            setAccountInfo({
              ...accountInfo,
              balanceInfo,
              recurlyEmail: email,
              recurlyFirst: firstName,
              recurlyLast: lastName,
              recurlyCompany: company,
              abn: abn?.replaceAll(' ', ''),
              billingAddress: {
                ...businessAddress,
                state: businessAddress.region,
                zipCode: businessAddress.postalCode,
              },
              // balanceInfo: response.balanceInfo,
            })
            setOrderData5CU(tempOrderData5CU)
            if (!accountInfo.billingAddress.country) setCurrentStep(0)
            if (industryId) setOrder({...order, template: industryId})
          } else {
            setAccountInfo({...accountInfo, balanceInfo})
          }
        } catch (err) {
          if (
            [404, 500].includes(err.status) ||
            err.message === 'missing subscription'
          ) {
            if (retriesAmount === 0) throw err
            setTimeout(() => fetchAccountSubscriptions(retriesAmount - 1), 1000)
          }
        }
      }
      fetchAccountSubscriptions()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    formatSelectedCentersAndApps()
  }, [formatSelectedCentersAndApps])

  useEffect(() => {
    getPlansPrices()
  }, [getPlansPrices])

  useEffect(() => {
    if (!plans || !accountSubscriptions) {
      return
    }

    const updatePrice = (sub, isAddon) => {
      const current = flatPlans.find(plan => plan.code === sub.code)
      for (const addon of sub.addons || []) {
        updatePrice(addon, true)
      }
      const total =
        selectedCountry === 'AU' && !isAddon ? sub.total : sub.subtotal
      if (!current || !total) return
      if (current.amount !== total) {
        shouldUpdate = true
        current.amount = total
      }
    }

    let shouldUpdate = false
    const plansCopy = structuredClone(plans)
    const flatPlans = [
      ...plansCopy.apps,
      ...plansCopy.centers,
      ...plansCopy.oneTimes,
    ]

    for (const sub of [
      ...accountSubscriptions.childSubscriptions,
      ...accountSubscriptions.parentSubscriptions,
    ]) {
      updatePrice(sub)
    }

    if (shouldUpdate) {
      setPlans(plansCopy)
    }
  }, [plans, accountSubscriptions, selectedCountry])

  const diableValidateAndProposalInput = false

  return (
    <CommandCenterLayout
      prevStep={prevStep}
      columnAmount={3}
      title="Catalog"
      centeredHeader={true}
      isLoading={isLoading}
      showBackButton={!isUpgrade}
      setShowUpdateAddressModal={setShowUpdateAddressModal}
    >
      {showUpdateAddressModal && (
        <UpdateAddressModal
          is5Order={true}
          nextStep={nextStep}
          selectedCountry={selectedCountry}
          accountInfo={accountInfo}
          openOnRender={true}
          updateAddressInfo={updateAddressInfo}
          setShowUpdateAddressModal={setShowUpdateAddressModal}
          order={order}
        />
      )}
      <CentersAndApps
        data={plans}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
        selectedCentersAndApps={selectedCentersAndApps}
        defaultCentersAndApps={defaultCentersAndApps.current}
        expiredCentersAndApps={expiredCentersAndApps.current}
        setSelectedOptions={setSelectedOptions}
        setQuantity={setQuantity}
        isUpgrade={isUpgrade}
        isSEOMBGDisabled={isSEOMBGDisabled}
        setIsWebsiteSettingsModalVisible={setIsWebsiteSettingsModalVisible}
        selectedCountry={selectedCountry}
        checkLegacySEO={accountSubscriptions?.accountInfo?.legacySEO}
        accountSubscriptionData={accountSubscriptionData}
        hasReportingCenterAccess={hasReportingCenterAccess}
        hasBusinessCenterAccess={hasBusinessCenterAccess}
        hasMarketingCenterAccess={hasMarketingCenterAccess}
      />
      <Options
        selectedCountry={selectedCountry}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
        selectedCentersAndApps={selectedCentersAndApps}
        setSelectedCentersAndApps={setSelectedCentersAndApps}
        proposalId={proposalId}
        setProposalId={setProposalId}
        proposalValidation={proposalValidation}
        setProposalValidation={setProposalValidation}
        alphaSEOFromRecurly={alphaSEOFromRecurly}
        alphaProposalId={alphaProposalId}
        setAlphaProposalId={setAlphaProposalId}
        alphaDomain={alphaDomain}
        setAlphaDomain={setAlphaDomain}
        alphaAmount={alphaAmount}
        setAlphaAmount={setAlphaAmount}
        // categories={categories}
        // setCategories={setCategories}
        // geos={geos}
        // setGeos={setGeos}
        isExternalDomain={isExternalDomain}
        setIsExternalDomain={setIsExternalDomain}
        alphaKeyCount={alphaKeyCount}
        setAlphaKeyCount={setAlphaKeyCount}
        alphaPowerboostCount={alphaPowerboostCount}
        setAlphaPowerboostCount={setAlphaPowerboostCount}
        diableValidateAndProposalInput={diableValidateAndProposalInput}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        mcDomainConfig={mcDomainConfig}
        setMcDomainConfig={setMcDomainConfig}
        seoDomainConfig={seoDomainConfig}
        setSeoDomainConfig={setSeoDomainConfig}
        thryvLeadsId={thryvLeadsId}
        setThryvLeadsId={setThryvLeadsId}
        thryvLeadsValidation={thryvLeadsValidation}
        setThryvLeadsValidation={setThryvLeadsValidation}
        futureBillingDate={futureBillingDate}
        setFutureBillingDate={setFutureBillingDate}
        sensisSiteDomain={sensisSiteDomain}
        setSensisSiteDomain={setSensisSiteDomain}
        quantity={quantity}
        setQuantity={setQuantity}
        seatQty={seatQty}
        isUpgrade={isUpgrade}
        oneTimeFees={plans?.oneTimes.filter(
          oneTime => oneTime.appliesTo.length > 0,
        )}
        centers={plans?.centers}
        isSEOMBGDisabled={isSEOMBGDisabled}
        setIsSEOMBGDisabled={setIsSEOMBGDisabled}
        defaultCentersAndApps={defaultCentersAndApps.current}
        expiredCentersAndApps={expiredCentersAndApps.current}
        isWebsiteSettingsModalVisible={isWebsiteSettingsModalVisible}
        setIsWebsiteSettingsModalVisible={setIsWebsiteSettingsModalVisible}
        isWebsiteValid={isWebsiteValid}
        setIsWebsiteValid={setIsWebsiteValid}
        setIsEaidFound={setIsEaidFound}
        isEaidFound={isEaidFound}
        setCohortEaid={setCohortEaid}
        cohortEaid={cohortEaid}
        upgradePaymentPreview={upgradePaymentPreview}
        setUpgradePaymentPreview={setUpgradePaymentPreview}
        setIsInvoiceBilled={setIsInvoiceBilled}
        setAllowInvoice={setAllowInvoice}
        setIsCohort={setIsCohort}
        isLegacyUpgradeAllowed={isLegacyUpgradeAllowed}
        setIsLegacyUpgradeAllowed={setIsLegacyUpgradeAllowed}
        thryvId={CCAccountInfo.thryvId}
        hasPaidDesignFee={
          accountInfo.hasPaidDesignFee ||
          accountSubscriptionData?.accountInfo?.hasPaidDesignFee
        }
        hasPaidOnboardingFee={
          accountInfo.hasPaidOnboardingFee ||
          accountSubscriptionData?.accountInfo?.hasPaidOnboardingFee
        }
      />
      <OrderSummary
        nextStep={nextStep}
        selectedCentersAndApps={selectedCentersAndApps}
        isWebsiteValid={isWebsiteValid}
        code={code}
        setCode={setCode}
        handleStartOver={handleStartOver}
        oneTimeFees={plans?.oneTimes.filter(
          oneTime => oneTime.appliesTo.length > 0,
        )}
        ccCouponInfo={ccCouponInfo}
        setCcCouponInfo={setCcCouponInfo}
        selectedCountry={selectedCountry}
        isUpgrade={isUpgrade}
        setAccountInfo={setAccountInfo}
        accountInfo={accountInfo}
        totalPrice={totalPrice}
        defaultCentersAndApps={defaultCentersAndApps.current}
        expiredCentersAndApps={expiredCentersAndApps.current}
        all5PlanPrices={all5PlanPrices}
        monthlyTotal={monthlyTotal}
        allowInvoice={allowInvoice}
        plans={plans}
        orderData5CU={orderData5CU}
        setOrderData5CU={setOrderData5CU}
        captureOrder5NewUpgrade={captureOrder5NewUpgrade}
        hasReportingCenterAccess={hasReportingCenterAccess}
      />
    </CommandCenterLayout>
  )
}

export {Catalog}
