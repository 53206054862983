import React from 'react'
import usaflag from '../assets/usaflag.png'
import caymanislandsflag from '../assets/caymanislandsflag.png'
import canflag from '../assets/canflag.png'
import barbadosflag from '../assets/barbadosflag.png'
import australia_flag from '../assets/australia_flag.svg'
import nzflag from '../assets/nz-flag.png'
import {
  CountryContainer,
  FlagContainer,
  FlagRow,
} from '../components/ui/country-selection'
import {CancelOrderModal} from '../components/cancel-order-modal'
import {useUserRoles} from '../utils/use-user-roles'

const COUNTRIES = [
  {name: 'United States', value: 'usa', isoValue: 'US', img: usaflag},
  {name: 'Australia', value: 'aus', isoValue: 'AU', img: australia_flag},
  {
    name: 'Cayman Islands',
    value: 'cayman',
    isoValue: 'KY',
    img: caymanislandsflag,
  },
  {name: 'Barbados', value: 'barbados', isoValue: 'BB', img: barbadosflag},
  {name: 'Canada', value: 'can', isoValue: 'CA', img: canflag},
  {name: 'New Zealand', value: 'nz', isoValue: 'NZ', img: nzflag},
]

function CountryFlag({img, countryName, isoValue, value, nextStep}) {
  return (
    <FlagContainer
      data-testid={isoValue}
      onClick={() => nextStep({selectedCountry: {isoValue, value}})}
    >
      <img src={img} alt={countryName} />
      <label className="text-center">{countryName}</label>
    </FlagContainer>
  )
}

function CountrySelection({nextStep}) {
  const {roles} = useUserRoles()

  const canUSAUser = roles.some(role =>
    ['InsideDemo', 'PremiseDemo', 'Premise', 'reseller'].includes(role),
  )
  const canAUUser = roles.some(role => ['Inside'].includes(role))
  const skipCountrySelection = roles.some(role =>
    ['VivialDemo', 'ThryvOnly'].includes(role),
  )

  const countriesToShow = canUSAUser
    ? COUNTRIES.filter(
        country =>
          country.name === 'United States' || country.name === 'Canada',
      )
    : canAUUser
    ? COUNTRIES.filter(
        country =>
          country.name === 'Australia' || country.name === 'New Zealand',
      )
    : COUNTRIES

  React.useEffect(() => {
    if (skipCountrySelection) {
      nextStep({
        selectedCountry: {
          isoValue: 'US',
          value: 'usa',
        },
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CountryContainer>
      <h2 className="mt-5 mb-3">Where are you selling Thryv?</h2>
      <FlagRow>
        {countriesToShow.map(({name, img, isoValue, value}, i) => (
          <CountryFlag
            key={i}
            className="flag"
            img={img}
            countryName={name}
            isoValue={isoValue}
            value={value}
            nextStep={nextStep}
          />
        ))}
      </FlagRow>
      <div className="d-flex justify-content-center">
        <CancelOrderModal cancelUrl="/orders" />
      </div>
    </CountryContainer>
  )
}

export {CountrySelection}
