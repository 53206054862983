import React, {useState} from 'react'
import {Modal, RadioGroup, Icon, Button, Input} from '@thryvlabs/maverick'
import {useAuthClient} from '../../utils/use-auth-client'

const RADIO_OPTIONS = [
  {
    name: 'No',
    value: false,
  },
  {
    name: 'Yes',
    value: true,
  },
]

function WebsiteSettingsModal({
  isWebsiteSettingsModalVisible,
  setIsWebsiteSettingsModalVisible,
  setSensisSiteDomain,
  isWebsiteValid,
  setIsWebsiteValid,
}) {
  const [isSensisValidationVisible, setIsSensisValidationVisible] = useState(
    RADIO_OPTIONS[0],
  )
  const [domain, setDomain] = useState('')
  const [error, setError] = useState({isError: false, message: ''})
  const [validDomainResponse, setValidDomainResponse] = useState(null)
  const client = useAuthClient()

  const handleClose = () => {
    if (
      validDomainResponse?.status === 'valid' &&
      isSensisValidationVisible.value
    ) {
      setSensisSiteDomain(domain)
    }
    setIsWebsiteSettingsModalVisible(false)
    setValidDomainResponse(null)
    setError({isError: false, message: ''})
    setDomain('')
    setIsSensisValidationVisible(RADIO_OPTIONS[0])
  }

  const handleRadioChange = option => {
    setIsSensisValidationVisible(option)
  }

  const validateDomain = async () => {
    const response = await client(`orders/validate-website/${domain}`)
    setError({isError: false, message: ''})
    setValidDomainResponse(response)
    setIsWebsiteValid(response.status === 'valid')
    if (response.status === 'invalid') {
      setError({isError: true, message: response.statusMessage})
    }
  }

  const handleChange = e => {
    setDomain(e.target.value)
  }

  return (
    <Modal
      openOnLoad={isWebsiteSettingsModalVisible}
      variant="default"
      title="Website"
      action
      btnActionText="Continue"
      btnAction={handleClose}
      footer
      hideX
      actionClose
      disableBackdropClick
      width="500px"
      data-testid="website-settings-modal"
    >
      <div style={{height: '300px'}}>
        <RadioGroup
          data={RADIO_OPTIONS}
          value={isSensisValidationVisible}
          onChange={handleRadioChange}
          row
          label="Do you have an existing published website you wish to transfer?"
        />
        {isSensisValidationVisible.value && (
          <div
            className="d-flex"
            style={{columnGap: '5px', margin: '25px 0 0'}}
          >
            <div style={{flexGrow: 1, position: 'relative'}}>
              <Input
                required
                type="text"
                placeholder="Your Domain"
                name="domain"
                withLabel
                labelType="static"
                variant="input-isolated"
                aria-label="Domain"
                onChange={handleChange}
                value={domain}
              />
              {!error.isError && validDomainResponse?.status === 'valid' && (
                <Icon
                  type="regular"
                  variant="circleCheck"
                  style={{
                    position: 'absolute',
                    right: 5,
                    top: 0,
                    bottom: 0,
                    margin: 'auto 0',
                  }}
                  color="#16A085"
                />
              )}
              {error.isError && validDomainResponse?.status === 'invalid' && (
                <Icon
                  type="regular"
                  variant="circleExclamation"
                  style={{
                    position: 'absolute',
                    right: 5,
                    top: 0,
                    bottom: 0,
                    margin: 'auto 0',
                  }}
                  color="#DF2A2A"
                />
              )}
            </div>
            <Button variant="primary" onClick={validateDomain}>
              Validate
            </Button>
          </div>
        )}
        {validDomainResponse?.status && isSensisValidationVisible.value && (
          <p
            className="m-0 pt-1"
            style={{
              fontSize: '12px',
              color:
                validDomainResponse?.status === 'invalid'
                  ? '#DF2A2A'
                  : '#16A085',
            }}
          >
            {validDomainResponse?.statusMessage}
          </p>
        )}
      </div>
    </Modal>
  )
}

export {WebsiteSettingsModal}
