import {useState, useEffect} from 'react'
import {useAuthClient} from './use-auth-client'
import {useLocation} from 'react-router'
import {MC_PLANS, BC_PLANS, RC_PLANS} from './constants'
import {ADD_ON} from './addons-data'

const WIN_BACK_4_COUPONS = {
  'plus-4-dexyp-m2m': 'pluswinback2022',
  'unlimited-4-dexyp-m2m': 'unlimitedwinback2022',
  'professional-4-dexyp-m2m': 'professionalwinback2022',
}

function useAutoCoupons({
  selectedPackage,
  selectedIndustryId,
  selectedCountry,
  selectedCentersAndApps,
  defaultCentersAndApps,
  expiredCentersAndApps,
  eligibleTransitionCenters,
  selectedAddons,
  upgradeAccountInfo,
  incentiveCoupon,
  setIncentiveCoupon,
  currentStep,
  setOrderCouponCodes,
  ccCouponInfo,
  upgradePaymentPreview,
  isEaidFound,
  isCohort,
}) {
  const [autoCouponInfo, setAutoCouponInfo] = useState([])
  const [appliedCoupons, setAppliedCoupons] = useState(false)
  const client = useAuthClient()
  const {pathname} = useLocation()

  const isNewOrder =
    pathname === '/order/new' &&
    selectedIndustryId !== 13 &&
    selectedIndustryId !== 12
  const isUpgrade = pathname === '/order/upgrade'
  const selectedPlan = selectedPackage || 'appmarket-5-thryv-m2m'
  const is5Plan = selectedPlan.includes('-5-')
  const FREE_ACCOUNT_CODES = [
    'appmarket-5-thryv-m2m',
    'cc_basicpls-5-thryv-m2m',
    'cc_basic-5-thryv-m2m',
    'addon-5-signatures_usage',
    'app-websitebuilder',
  ]
  const findSensisSiteDomain = () => {
    if (selectedCentersAndApps) {
      return selectedCentersAndApps.apps.find(app => app.sensisSiteDomain)
        ?.sensisSiteDomain
    }
    return selectedAddons?.find(
      x => x.product === ADD_ON.add_ons['Website'].label,
    )?.sensisSiteDomain
  }
  const resetAutoCoupons = () => {
    setAutoCouponInfo([])
    setAppliedCoupons(false)
  }

  useEffect(() => {
    if (
      (currentStep < 6 && !isUpgrade) ||
      (isUpgrade &&
        ((currentStep < 2 && is5Plan) || (currentStep < 3 && !is5Plan)))
    )
      return

    async function fetchCoupon({couponCode, planCode, addonsString}) {
      const baseUrl = 'orders/coupon/validate'
      const addonsQueryString = addonsString
        ? `addonsString=${addonsString}&`
        : ''
      const url = `${baseUrl}?${addonsQueryString}couponCode=${couponCode}&country=${selectedCountry.isoValue}&planCode=${planCode}`
      const coupon = await client(url)
      return coupon
    }

    async function applyAutoCoupons() {
      let isFreeAccountWithoutExpiredSubs = true
      const isFreeAccount = !(
        defaultCentersAndApps?.current?.centers?.some(
          center => !FREE_ACCOUNT_CODES.includes(center.code),
        ) ||
        defaultCentersAndApps?.current?.apps?.some(
          app => !FREE_ACCOUNT_CODES.includes(app.code),
        )
      )
      if (isUpgrade) {
        isFreeAccountWithoutExpiredSubs = !(
          expiredCentersAndApps?.current?.centers?.some(
            center => !FREE_ACCOUNT_CODES.includes(center.code),
          ) ||
          expiredCentersAndApps?.current?.apps?.some(
            app => !FREE_ACCOUNT_CODES.includes(app.code),
          )
        )
      }
      console.log(
        'isFreeAccountWithoutExpiredSubs' + isFreeAccountWithoutExpiredSubs,
      )
      const codes = []
      for (const key in selectedCentersAndApps) {
        codes.push(...selectedCentersAndApps[key].map(x => x.code))
      }
      const response = await client(
        `cc/order/fetchPlanCoupons/${selectedCountry.isoValue}/${codes.join()}`,
      )
      let autoCouponData = []
      if (response) {
        for (let coupon of response) {
          let couponApplicable = false
          if (coupon?.new_client_only_flag === 'Y') {
            if (isFreeAccount && isFreeAccountWithoutExpiredSubs) {
              couponApplicable = true
            } else {
              couponApplicable = false
            }
          } else {
            couponApplicable = true
          }
          if (couponApplicable) {
            let planPrefix = coupon?.plan_code.slice(0, 2)
            switch (planPrefix) {
              case 'bc':
                {
                  const isBCInCart = selectedCentersAndApps?.centers.some(
                    ({code}) => BC_PLANS.includes(code),
                  )
                  const isBCInDefault =
                    defaultCentersAndApps?.current?.centers.some(({code}) =>
                      BC_PLANS.includes(code),
                    )
                  if (isBCInCart) {
                    if (!isBCInDefault) {
                      const createUrl = `orders/coupon/validate?couponCode=${coupon?.coupon_code}&country=${selectedCountry.isoValue}&planCode=${coupon?.plan_code}`
                      let couponResponse = await client(createUrl)
                      autoCouponData.push(couponResponse)
                    }
                  }
                }
                break
              case 'mc':
                {
                  const isMCInCart = selectedCentersAndApps?.centers.some(
                    ({code}) => MC_PLANS.includes(code),
                  )
                  const isMCInDefault =
                    defaultCentersAndApps?.current?.centers.some(({code}) =>
                      MC_PLANS.includes(code),
                    )
                  if (isMCInCart) {
                    if (!isMCInDefault) {
                      const createUrl = `orders/coupon/validate?couponCode=${coupon?.coupon_code}&country=${selectedCountry.isoValue}&planCode=${coupon?.plan_code}`
                      let couponResponse = await client(createUrl)
                      autoCouponData.push(couponResponse)
                    }
                  }
                }
                break
              case 'rc':
                {
                  const isRCInCart = selectedCentersAndApps?.centers.some(
                    ({code}) => RC_PLANS.includes(code),
                  )
                  const isRCInDefault =
                    defaultCentersAndApps?.current?.centers.some(({code}) =>
                      RC_PLANS.includes(code),
                    )
                  if (isRCInCart) {
                    if (!isRCInDefault) {
                      const createUrl = `orders/coupon/validate?couponCode=${coupon?.coupon_code}&country=${selectedCountry.isoValue}&planCode=${coupon?.plan_code}`
                      let couponResponse = await client(createUrl)
                      autoCouponData.push(couponResponse)
                    }
                  }
                }
                break
              default:
                break
            }
          }
        }
      }
      const isWebsiteApp =
        selectedCentersAndApps?.apps.find(
          ({name}) => name === 'Professionally Designed Website',
        ) || selectedAddons?.find(({product}) => product === 'Website')
      const isTrialPlan = upgradeAccountInfo?.planCode?.includes('trial')
      const sensisDomain = findSensisSiteDomain()
      const addonsString =
        (!selectedCentersAndApps
          ? selectedAddons
              ?.filter(addon => addon.planCode !== undefined)
              ?.map(addon => addon.planCode)
          : [
              ...selectedCentersAndApps.centers,
              ...selectedCentersAndApps.apps,
              ...selectedCentersAndApps.oneTimes,
            ]
              .map(obj => obj.code)
              .join(',')) || ''
      let incentive = incentiveCoupon
      let data

      if (!isNewOrder && upgradeAccountInfo.status !== 'active') {
        const winBackCoupon = WIN_BACK_4_COUPONS[selectedPlan]
        if (winBackCoupon)
          data = {couponCode: winBackCoupon, planCode: selectedPlan}
      } else if (isTrialPlan) {
        data = {
          couponCode: 'transition75',
          planCode: selectedPlan,
        }
      }

      let domainData

      if ((isWebsiteApp?.name || isWebsiteApp?.product) && sensisDomain) {
        domainData = {
          couponCode: 'urldbdesignfee',
          planCode: selectedPlan,
        }
      }

      const couponInfo = data && (await fetchCoupon({...data, addonsString}))
      const domainCoupon =
        domainData && (await fetchCoupon({...domainData, addonsString}))

      const cohortCouponApplies =
        isCohort &&
        (selectedCentersAndApps?.oneTimes.find(
          fee => fee.code === 'thryvprfee',
        ) ||
          selectedAddons?.find(addon => addon.planCode === 'onboarding-fee'))

      const cohortCoupon =
        cohortCouponApplies &&
        (await fetchCoupon({
          couponCode: 'cohort-waive-onboardingfee',
          addonsString,
        }))

      if (incentive) setIncentiveCoupon(couponInfo)

      if (couponInfo) autoCouponData.push(couponInfo)
      if (domainCoupon) autoCouponData.push(domainCoupon)
      if (cohortCoupon) autoCouponData.push(cohortCoupon)

      if (autoCouponData.length > 0) setAutoCouponInfo(autoCouponData)
    }
    applyAutoCoupons()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, upgradePaymentPreview])

  useEffect(() => {
    if (
      ((currentStep < 6 && !isUpgrade) ||
        (isUpgrade && ((currentStep < 2 && is5Plan) || !is5Plan))) &&
      appliedCoupons
    )
      return
    setOrderCouponCodes(
      [...autoCouponInfo, ccCouponInfo?.isValid ? ccCouponInfo : null].filter(
        Boolean,
      ),
    )
    setAppliedCoupons(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    autoCouponInfo,
    ccCouponInfo,
    setOrderCouponCodes,
    currentStep,
    appliedCoupons,
  ])

  return {autoCouponInfo, resetAutoCoupons}
}

export default useAutoCoupons
